import FileSystemBash from "./fileSystemBash";
import Applications from "./applications";
export default function Bash(print) {
    const fileSystem = FileSystemBash();
    let path = { p: fileSystem.goHome() };
    const getApp = Applications(print, path);
    function splitArgs(a) {
        const args = [];
        const options = [];
        a.forEach((v) => {
            if (v === "")
                return;
            if (v.charAt(0) === "-") {
                options.push(v);
                return;
            }
            args.push(v);
        });
        return [args, options];
    }
    function cmdNotFound(cmdName) {
        print(`\n${cmdName}:command not found`);
    }
    function prompt() {
        let out = "";
        for (let i = 0; i < path.p.length; i++) {
            out += path.p[i].name;
            if (i !== 0 && i < path.p.length - 1)
                out += "/";
        }
        out = out.replace(/^\/home\/user/, "~");
        if (out !== "~")
            out += " ";
        print(`\nuser:${out}$`);
    }
    function input(cmd) {
        cmd = cmd.replaceAll(/\s+/g, " ");
        const cmdSplit = cmd.split(" ");
        const cmdName = cmdSplit[0];
        const cmdArgs = cmdSplit.slice(1);
        console.log("cmd", cmdName, cmdArgs);
        if (cmd) {
            const app = getApp(cmdName);
            if (app) {
                const [args, options] = splitArgs(cmdArgs);
                app(args, options);
            }
            else
                cmdNotFound(cmdName);
        }
        prompt();
    }
    return { input };
}
