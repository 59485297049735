// @ts-ignore
import titleText from "../text/title.md";
import Bash from "./bash";
export default function Terminal(screenTextEngine) {
    const canvas = document.querySelector("canvas.webgl");
    const textarea = document.getElementById("textarea");
    textarea.value = "";
    textarea.readOnly = true;
    textarea.blur();
    // screenTextEngine.placeMarkdown("###  Welcome to ED-Linux 1.0 LTS");
    screenTextEngine.placeMarkdown(titleText);
    screenTextEngine.placeText("user:~$");
    const bash = Bash((s, md = false) => {
        if (md) {
            const numOfpx = screenTextEngine.placeMarkdown(s);
            screenTextEngine.scroll(numOfpx, "px", {
                updateMaxScroll: true,
                moveView: false,
            });
            screenTextEngine.scroll(12, "lines", {
                updateMaxScroll: false,
                moveView: true,
            });
        }
        else {
            const numOfLines = screenTextEngine.placeText(s);
            screenTextEngine.scroll(numOfLines, "lines");
        }
    });
    let oldText = "";
    function onInput() {
        const change = stringEditDistance(oldText, textarea.value);
        oldText = textarea.value;
        if (change)
            screenTextEngine.userInput(change, textarea.selectionStart);
        screenTextEngine.scrollToEnd();
    }
    textarea.addEventListener("input", onInput, false);
    canvas.addEventListener("pointerup", (ev) => {
        if (ev.pointerType === "mouse") {
            textarea.readOnly = false;
            textarea.focus();
            textarea.setSelectionRange(lastSelection, lastSelection);
        }
        else {
            textarea.readOnly = true;
            textarea.blur();
        }
    });
    window.addEventListener("keypress", (e) => {
        if (textarea.readOnly === true ||
            document.activeElement?.id !== "textarea") {
            textarea.readOnly = false;
            textarea.focus();
            if (e.key.length === 1) {
                textarea.value =
                    textarea.value.slice(0, lastSelection) +
                        e.key +
                        textarea.value.slice(lastSelection);
                lastSelection += 1;
                onInput();
            }
            textarea.setSelectionRange(lastSelection, lastSelection);
        }
        // textarea
        if (e.key === "Enter") {
            screenTextEngine.freezeInput();
            bash.input(textarea.value);
            textarea.value = "";
            const change = stringEditDistance(oldText, textarea.value);
            oldText = textarea.value;
            if (change)
                screenTextEngine.userInput(change, textarea.selectionStart);
        }
    });
    window.addEventListener("keydown", (e) => {
        switch (e.key) {
            case "ArrowUp":
                e.preventDefault();
                screenTextEngine.scroll(-1, "lines", {
                    moveView: true,
                    updateMaxScroll: false,
                });
                break;
            case "ArrowDown":
                e.preventDefault();
                screenTextEngine.scroll(1, "lines", {
                    moveView: true,
                    updateMaxScroll: false,
                });
                break;
        }
    });
    let lastSelection = 0;
    document.addEventListener("selectionchange", () => {
        if (textarea.selectionStart !== textarea.selectionEnd)
            textarea.setSelectionRange(lastSelection, lastSelection);
        lastSelection = textarea.selectionStart;
        screenTextEngine.userInput({ type: "none", loc: "none", str: "" }, textarea.selectionStart);
    });
    function stringEditDistance(oldStr, newStr) {
        const lenDiff = oldStr.length - newStr.length;
        let change = {
            type: "none",
            loc: "none",
            str: "",
        };
        let op = 0;
        let np = 0;
        if (lenDiff === 0) {
        }
        else if (lenDiff > 0) {
            change.type = "del";
            while (op < oldStr.length || np < newStr.length) {
                if (op >= oldStr.length) {
                    console.error("add and del");
                    return;
                }
                if (oldStr.charAt(op) !== newStr.charAt(np)) {
                    if (change.loc === "none")
                        change.loc = np === newStr.length ? "end" : np;
                    change.str += oldStr.charAt(op);
                    op++;
                }
                else {
                    op++;
                    np++;
                }
            }
        }
        else if (lenDiff < 0) {
            change.type = "add";
            while (op < oldStr.length || np < newStr.length) {
                if (np >= newStr.length) {
                    console.error("add and del");
                    return;
                }
                if (oldStr.charAt(op) !== newStr.charAt(np)) {
                    if (change.loc === "none")
                        change.loc = op === oldStr.length ? "end" : op;
                    change.str += newStr.charAt(np);
                    np++;
                }
                else {
                    op++;
                    np++;
                }
            }
        }
        return change;
    }
}
