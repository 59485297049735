import FileSystemBash from "../fileSystemBash";
export default function ls(print, path) {
    const fileSystem = FileSystemBash();
    const docs = {
        name: "ls",
        short: "list directory contents",
        long: "",
    };
    const app = (args, options) => {
        if (options.find((o) => o === "-h" || o === '-help')) {
            print(`\n${docs.name} – ${docs.short}`);
            return;
        }
        let out = "\n";
        const files = fileSystem.getChildren(path.p);
        for (const f of files) {
            out += `${f.name}\n`;
        }
        print(out);
    };
    return { docs, app };
}
