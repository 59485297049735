// @ts-ignore
import titleMD from "../text/title.md";
// @ts-ignore
import titleLegacyMD from "../text/title-legacy.md";
// @ts-ignore
import aboutMD from "../text/about.md";
// @ts-ignore
import contactMD from "../text/contact.md";
const disk = {
    name: "/",
    children: [
        { name: "bin", children: [] },
        { name: "dev", children: [] },
        {
            name: "home",
            children: [
                {
                    name: "user",
                    children: [
                        {
                            name: "title",
                            children: [
                                { name: "title.md", data: titleMD },
                                { name: "title-legacy.md", data: titleLegacyMD },
                            ],
                        },
                        {
                            name: "about",
                            children: [
                                {
                                    name: "images",
                                    children: [
                                        {
                                            name: "ed.png",
                                            data: "\n\n!(./images/ed.png)[1.5]",
                                        },
                                    ],
                                },
                                { name: "about.md", data: aboutMD },
                            ],
                        },
                        {
                            name: "contact",
                            children: [{ name: "contact.md", data: contactMD }],
                        },
                    ],
                },
            ],
        },
        { name: "lib64", children: [] },
        { name: "media", children: [] },
        { name: "home", children: [] },
    ],
};
export default function FileSystemBash() {
    function _pathStrToArr(p) {
        const pathArray = p.split("/");
        // remove trailling slash
        if (pathArray.length > 0 && pathArray.at(-1) === "")
            pathArray.pop();
        return pathArray;
    }
    function _buildPath(path, newPathArray) {
        for (const p of newPathArray) {
            switch (p) {
                case "":
                    // go to root
                    path = [disk];
                    break;
                case "..":
                    // go up a folder
                    if (path.length > 1)
                        path.pop();
                    break;
                case "~":
                    // go home
                    path = goHome();
                    break;
                case ".":
                    // current folder
                    break;
                default:
                    // goto next location
                    const currentFolder = path.at(-1);
                    if (!currentFolder || !("children" in currentFolder))
                        return undefined;
                    const next = currentFolder.children.find((m) => m.name === p);
                    if (!next)
                        return undefined;
                    path.push(next);
                    break;
            }
        }
        return path;
    }
    function getChildren(path) {
        return path[path.length - 1].children;
    }
    function goHome() {
        const home = disk.children.find((m) => m.name === "home");
        const user = home.children.find((m) => m.name === "user");
        return [disk, home, user];
    }
    function goto(path, newPath) {
        return _buildPath([...path], _pathStrToArr(newPath));
    }
    function make(path, newPath, type) {
        const newPathArray = _pathStrToArr(newPath);
        const name = newPathArray.pop();
        if (name === undefined)
            return "bad_args";
        const currentPath = _buildPath([...path], newPathArray);
        const currentFolder = currentPath?.at(-1);
        if (!currentFolder || !("children" in currentFolder))
            return "bad_path";
        // Check if folder allready exisits
        if (currentFolder.children.find((m) => m.name === name))
            return "file_exists";
        currentFolder.children.push(type === "folder" ? { name, children: [] } : { name, data: "" });
        return "ok";
    }
    return { getChildren, goHome, goto, make };
}
