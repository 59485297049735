export default function echo(print, path) {
    const docs = {
        name: "echo",
        short: "display a line of text",
        long: "",
    };
    const app = (args, options) => {
        if (options.find((o) => o === "-h" || o === '-help')) {
            print(`\n${docs.name} – ${docs.short}`);
            return;
        }
        const str = args.join(" ");
        print(`\n${str}`);
    };
    return { docs, app };
}
