import FileSystemBash from "../fileSystemBash";
export default function show(print, path) {
    const fileSystem = FileSystemBash();
    const docs = {
        name: "show",
        short: "render markdown (.md) files",
        long: "",
    };
    const app = (args, options) => {
        if (options.find((o) => o === "-h" || o === "-help")) {
            print(`\n${docs.name} – ${docs.short}`);
            return;
        }
        if (options.find((o) => o === "-a" || o === "-all")) {
            let allStr = "";
            const files = fileSystem.goHome().at(-1);
            if (!files)
                return;
            const showAll = (p) => {
                p.forEach((e) => {
                    if (e.name === "title" || e.name === "images")
                        return;
                    if (e.name === "projects") {
                        allStr += "\n\n\n\n# Projects";
                    }
                    if (e.name.match(".md")) {
                        allStr += e.data;
                        return;
                    }
                    showAll(e.children);
                });
            };
            showAll(files.children);
            print(allStr, true);
            return;
        }
        if (args.length === 0) {
            print(`\nMissing filename`);
            return;
        }
        const file = fileSystem.goto(path.p, args[0])?.at(-1);
        if (!file) {
            print(`\nNo such file or directory`);
            return;
        }
        if (!("data" in file)) {
            print(`\n${file.name}:not a file`);
            return;
        }
        print(file.data, true);
    };
    return { docs, app };
}
