import * as THREE from "three";
// @ts-ignore
import vertexShader from "../shaders/vertex.vert";
// @ts-ignore
import lagFragmentShader from "../shaders/lag.frag";
import { ShaderToScreen } from "./shaderToScreen";
class Lag {
    outputCopy;
    shaderToScreen1;
    outputTexture;
    lagMat;
    constructor(buffer, width, height) {
        this.lagMat = new THREE.ShaderMaterial();
        this.shaderToScreen1 = new ShaderToScreen({
            uniforms: {
                uDiffuse: { value: buffer.texture },
                uLagTex: { value: null },
                uNeedUpdate: { value: false },
            },
            vertexShader: vertexShader,
            fragmentShader: lagFragmentShader,
        }, width, height);
        this.outputTexture = this.shaderToScreen1.outputTexture;
        this.outputCopy = new ShaderToScreen({
            uniforms: {
                uDiffuse: { value: this.outputTexture.texture },
            },
            vertexShader: vertexShader,
            fragmentShader: `uniform sampler2D uDiffuse; varying vec2 vUv; void main() {gl_FragColor = texture2D(uDiffuse, vUv);}`,
        }, width, height);
        this.shaderToScreen1.shader.uniforms.uLagTex.value =
            this.outputCopy.outputTexture.texture;
    }
    render(renderer, tex) {
        this.shaderToScreen1.render(renderer);
        this.outputCopy.render(renderer);
    }
}
export { Lag };
