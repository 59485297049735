import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
function loadAssists(callback) {
    const assists = {};
    const loadingDOM = document.querySelector("#loading");
    const loadingItemsDOM = document.querySelector("#loading-items");
    const loadingBarDOM = document.querySelector("#loading-bar-progress");
    const manager = new THREE.LoadingManager();
    manager.onStart = function (url, itemsLoaded, itemsTotal) {
        console.log("Started loading file: " +
            url +
            ".\nLoaded " +
            itemsLoaded +
            " of " +
            itemsTotal +
            " files.");
    };
    manager.onLoad = function () {
        if (!loadingItemsDOM)
            return;
        loadingItemsDOM.textContent = `Nearly There...`;
        console.log("Loading complete!");
        window.setTimeout(() => {
            loadingDOM.style.opacity = "0";
            callback(assists);
        }, 200);
        window.setTimeout(() => {
            loadingDOM.style.display = "none";
        }, 500);
    };
    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
        if (!loadingItemsDOM || !loadingBarDOM)
            return;
        loadingBarDOM.style.transform = `scaleX(${itemsLoaded / itemsTotal})`;
        loadingItemsDOM.textContent = `${itemsLoaded} of ${itemsTotal} File Loaded: ${url}`;
        console.log("Loading file: " +
            url +
            ".\nLoaded " +
            itemsLoaded +
            " of " +
            itemsTotal +
            " files.");
    };
    // Fonts
    const fontLoader = new FontLoader(manager);
    fontLoader.load("/fonts/public-pixel.json", (font) => {
        assists.publicPixelFont = font;
    });
    fontLoader.load("/fonts/chill.json", (font) => {
        assists.chillFont = font;
    });
    // Texture
    // Texture
    const textureLoader = new THREE.TextureLoader(manager);
    textureLoader.load("/textures/bake-quality-5.jpg", (tex) => {
        tex.flipY = false;
        tex.encoding = THREE.sRGBEncoding;
        assists.bakeTexture = tex;
    });
    textureLoader.load("/textures/bake_floor-quality-3.jpg", (tex) => {
        tex.flipY = false;
        tex.encoding = THREE.sRGBEncoding;
        assists.bakeFloorTexture = tex;
    });
    const cubeTextureLoader = new THREE.CubeTextureLoader(manager);
    cubeTextureLoader.load([
        `/textures/environmentMap/px.jpg`,
        `/textures/environmentMap/nx.jpg`,
        `/textures/environmentMap/py.jpg`,
        `/textures/environmentMap/ny.jpg`,
        `/textures/environmentMap/pz.jpg`,
        `/textures/environmentMap/nz.jpg`,
    ], (tex) => {
        assists.environmentMapTexture = tex;
    });
    // Mesh
    const gltfLoader = new GLTFLoader(manager);
    gltfLoader.load("/models/Commodore710_33.5.glb", (gltf) => {
        assists.screenMesh = gltf.scene.children.find((m) => m.name === "Screen");
        assists.computerMesh = gltf.scene.children.find((m) => m.name === "Computer");
        assists.crtMesh = gltf.scene.children.find((m) => m.name === "CRT");
        assists.keyboardMesh = gltf.scene.children.find((m) => m.name === "Keyboard");
        assists.shadowPlaneMesh = gltf.scene.children.find((m) => m.name === "ShadowPlane");
    });
}
export { loadAssists };
