import FileSystemBash from "../fileSystemBash";
export default function hello(print, path) {
    const fileSystem = FileSystemBash();
    const docs = {
        name: "hello",
        short: "friendly greeting program",
        long: "",
    };
    const app = (args, options) => {
        if (options.find((o) => o === "-h" || o === '-help')) {
            print(`\n${docs.name} – ${docs.short}`);
            return;
        }
        print("\nHello, world!");
    };
    return { docs, app };
}
